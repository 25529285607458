import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLocationDot, faPhone, faClock, faTrainSubway, faArrowRight, faEnvelope, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createGtm } from '@gtm-support/vue-gtm'
import { createMetaManager } from 'vue-meta'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './assets/styles/app.scss'

library.add(
  faInstagram,
  faFacebookF,
  faClock,
  faLocationDot,
  faPhone,
  faTrainSubway,
  faArrowRight,
  faEnvelope,
  faChevronDown
)

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(createGtm({
    id: process.env.VUE_APP_GTM_ID,
    debug: process.env.NODE_ENV === 'development',
    queryParams: {
      gtm_auth: process.env.VUE_APP_GTM_AUTH,
      gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
      gtm_cookies_win: 'x'
    },
    vueRouter: router
  }))
  .use(createMetaManager())
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyB_u7oKmFdvWvI0ZSZ8qRonDBj4m0cD7To'
    }
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .directive('links-in-new-window', {
    mounted (el) {
      const links = el.querySelectorAll('a')
      links.forEach((link: HTMLAnchorElement) => {
        link.target = '_blank'
      })
    }
  })
  .mount('#app')
