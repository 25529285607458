import { useGtm } from '@gtm-support/vue-gtm'

interface trackSocialLinkFunc {
  (label: string): void;
}

export default (): { trackSocialLink: trackSocialLinkFunc; } => {
  const gtm = useGtm()

  const trackSocialLink = (label: string) => {
    if (gtm && gtm.enabled()) {
      gtm.trackEvent({
        event: 'interaction',
        category: 'social_links',
        action: 'click',
        label
      })
    }
  }

  return {
    trackSocialLink
  }
}
