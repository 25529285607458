import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97b925dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-primary" }
const _hoisted_2 = { class: "nav-block" }
const _hoisted_3 = { class: "nav-block" }
const _hoisted_4 = { class: "nav-secondary" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["nav", {'-opened': _ctx.navOpened}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: {name: 'productsPage'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('products')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name: 'productors'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('productors')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name: 'values'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('values')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name: 'deposit'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('deposit')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: {name: 'faq'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('faq')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name: 'about'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('about')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: {name: 'contact'},
            class: "nav-item link -plain-hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('contact')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: _ctx.t('facebookURL'),
        target: "_blank",
        class: "nav-item link -simple-hover",
        onClick: _cache[0] || (_cache[0] = () => _ctx.trackSocialLink('facebook_nav'))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fab', 'facebook-f'] })
      ], 8, _hoisted_5),
      _createElementVNode("a", {
        href: _ctx.t('instagramURL'),
        target: "_blank",
        class: "nav-item link -simple-hover",
        onClick: _cache[1] || (_cache[1] = () => _ctx.trackSocialLink('instragram_nav'))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fab', 'instagram'] })
      ], 8, _hoisted_6)
    ])
  ], 2))
}