import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'

// interface Pagebuilder {
//   id: number
// }

// interface Entry {
//   title: string,
//   pagebuilder: Pagebuilder
// }

// interface Result {
//   entry: Entry
// }

// eslint-disable-next-line
export default (section: string, variables: any) => {
  const query = `
  query ${section}Content($site: [String!]) {
    entry(section: "${section}", site: $site) {
      title
      ... on ${section}_${section}_Entry {
        id
        pagebuilder {
          ... on pagebuilder_blockText_BlockType {
            id
            typeHandle
            pbText
            pbTitle
            pbButton {
              ... on pbButton_BlockType {
                id
                external
                label
                internal {
                  sectionHandle
                }
              }
            }
            pbBackground
          }
          ... on pagebuilder_blockTextImage_BlockType {
            id
            typeHandle
            pbText
            pbTitle
            pbButton {
              ... on pbButton_BlockType {
                id
                external
                label
                internal {
                  sectionHandle
                }
              }
            }
            pbImage {
              id
              title
              size420: url @transform(width: 420)
              size640: url @transform(width: 640)
              size860: url @transform(width: 860)
            }
            pbImagePosition
          }
          ... on pagebuilder_blockFAQ_BlockType {
            id
            typeHandle
            pbQuestion
            pbAnswer
          }
          ... on pagebuilder_blockGallery_BlockType {
            id
            typeHandle
            pbTitle
            pbImages {
              id
              title
              size420: url @transform(width: 420)
              size640: url @transform(width: 640)
              size860: url @transform(width: 860)
            }
            pbButton {
              ... on pbButton_BlockType {
                id
                external
                label
                internal {
                  sectionHandle
                }
              }
            }
          }
          ... on pagebuilder_blockInfo_BlockType {
            id
            typeHandle
            pbTitle
          }
          ... on pagebuilder_blockSpacer_BlockType {
            id
            typeHandle
            pbPattern
          }
        }
      }
    }
  }`

  return useQuery(gql`${query}`, variables)
}
