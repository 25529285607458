
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import useTrackers from '@/helpers/useTrackers'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Footer',
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    const store = useStore()
    const currentYear = computed(() => new Date().getFullYear())
    const oppositeLocale = computed(() => store.getters['Global/oppositeLocale'])
    const updateLocale = (locale: string) => store.dispatch('Global/updateLocale', locale)

    const { trackSocialLink } = useTrackers()

    return {
      t,
      currentYear,
      oppositeLocale,
      updateLocale,
      trackSocialLink
    }
  }
})
