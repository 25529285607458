export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products"])},
        "productors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our productors"])},
        "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our values"])},
        "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our deposit system"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are we?"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "oppositeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])}
      },
      "fr": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos produits"])},
        "productors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos producteurs"])},
        "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos valeurs"])},
        "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos consignes"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous?"])},
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "oppositeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])}
      }
    }
  })
}
