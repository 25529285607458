import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '@/views/Home.vue'
import store from '@/store'

// const data = [
//   {
//     path: {
//       fr: '/',
//       en: '/'
//     },
//     name: 'home',
//     component: Home
//   },
//   {
//     path: {
//       fr: '/nos-produits',
//       en: '/our-products'
//     },
//     name: 'products',
//     component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
//   },
//   {
//     path: {
//       fr: '/nos-producteurs',
//       en: '/our-productors'
//     },
//     name: 'productors',
//     component: () => import(/* webpackChunkName: "productors" */ '../views/Productors.vue')
//   },
//   {
//     path: '/nos-valeurs',
//     name: 'values',
//     component: () => import(/* webpackChunkName: "values" */ '../views/Values.vue')
//   },
//   {
//     path: '/notre-systeme-de-consigne',
//     name: 'deposit',
//     component: () => import(/* webpackChunkName: "deposit" */ '../views/Deposit.vue')
//   },
//   {
//     path: '/qui-sommes-nous',
//     name: 'about',
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   },
//   {
//     path: '/faq',
//     name: 'faq',
//     component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
//   },
//   {
//     path: '/contact',
//     name: 'contact',
//     component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
//   }
// ]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/nos-produits',
    name: 'productsPage',
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/nos-producteurs',
    name: 'productors',
    component: () => import(/* webpackChunkName: "productors" */ '../views/Productors.vue')
  },
  {
    path: '/nos-valeurs',
    name: 'values',
    component: () => import(/* webpackChunkName: "values" */ '../views/Values.vue')
  },
  {
    path: '/notre-systeme-de-consigne',
    name: 'deposit',
    component: () => import(/* webpackChunkName: "deposit" */ '../views/Deposit.vue')
  },
  {
    path: '/qui-sommes-nous',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ top: 0 })
    //   }, 500)
    // })
  }
})

router.beforeEach(() => {
  store.dispatch('Global/closeNav')
})

export default router
