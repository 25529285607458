import { Commit } from 'vuex'

interface GlobalState {
  locale: string,
  navOpened: boolean,
  isLoading: boolean
}

const state: GlobalState = {
  locale: 'fr',
  navOpened: false,
  isLoading: false
}

export default {
  namespaced: true,
  state,
  mutations: {
    SET_LOCALE (state: GlobalState, value: string): void {
      state.locale = value
    },
    SET_NAV_OPENED (state: GlobalState, value: boolean): void {
      state.navOpened = value
    },
    SET_IS_LOADING (state: GlobalState, value: boolean): void {
      state.isLoading = value
    }
  },
  actions: {
    updateLocale ({ commit }: { commit: Commit}, locale: string): void {
      commit('SET_LOCALE', locale)
    },
    toggleNav ({ commit, state }: { commit: Commit, state: GlobalState }): void {
      commit('SET_NAV_OPENED', !state.navOpened)
    },
    openNav ({ commit }: { commit: Commit }): void {
      commit('SET_NAV_OPENED', true)
    },
    closeNav ({ commit }: { commit: Commit }): void {
      commit('SET_NAV_OPENED', false)
    },
    updateIsLoading ({ commit }: { commit: Commit }, isLoading: boolean): void {
      commit('SET_IS_LOADING', isLoading)
    }
  },
  getters: {
    locale: (state: GlobalState):string => state.locale,
    oppositeLocale: (state: GlobalState): string => state.locale === 'en' ? 'fr' : 'en',
    navOpened: (state: GlobalState): boolean => state.navOpened,
    isLoading: (state: GlobalState): boolean => state.isLoading
  }
}
