
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import Nav from '@/components/Nav.vue'

import logo from '@/assets/img/logo.png'
import logo2x from '@/assets/img/logo2x.png'

export default defineComponent({
  name: 'Header',
  components: {
    Nav
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    const store = useStore()
    const toggleNav = () => store.dispatch('Global/toggleNav')
    const navOpened = computed(() => store.getters['Global/navOpened'])

    return {
      t,
      toggleNav,
      navOpened,
      logo,
      logo2x
    }
  }
})
